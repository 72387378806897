import "@hotwired/turbo-rails"
import "./controllers/web"
import * as bootstrap from "bootstrap"

console.log("Hello World from Ward's Rides!");

function toggleElement(element) {
    document.getElementById(element).classList.toggle('d-none');
}

window.toggleElement = toggleElement;

function toggleAccordion() {
    document.getElementById('bearToothButton').classList.toggle('d-none');
    document.getElementById('bearToothContent').classList.toggle('d-none');
    document.getElementById('crossCountryButton').classList.toggle('d-none');
    document.getElementById('crossCountryContent').classList.toggle('d-none');
}

window.toggleAccordion = toggleAccordion;

function toggleAccordionWithFade() {
    // Elements to toggle
    const bearToothButton = document.getElementById('bearToothButton');
    const bearToothContent = document.getElementById('bearToothContent');
    const crossCountryButton = document.getElementById('crossCountryButton');
    const crossCountryContent = document.getElementById('crossCountryContent');

    // Array of elements for easier handling
    const elements = [bearToothButton, bearToothContent, crossCountryButton, crossCountryContent];

    elements.forEach(element => {
        if (element.classList.contains('d-none')) {
            // If currently hidden, prepare to show
            element.classList.remove('d-none');
            element.classList.add('fade-in');
            element.style.opacity = '0';

            // Trigger reflow to ensure the transition works
            void element.offsetWidth;

            // Start fade in
            element.style.opacity = '1';
        } else {
            // Start fade out
            element.style.opacity = '0';

            // After animation completes, hide the element
            element.addEventListener('transitionend', function hideElement() {
                element.classList.add('d-none');
                element.classList.remove('fade-in');
                element.removeEventListener('transitionend', hideElement);
            }, { once: true });
        }
    });
}

window.toggleAccordionWithFade = toggleAccordionWithFade;