import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
      "checkbox"
  ];

  static values = {
    target: String
  }

  validate(event) {
    const anyChecked = this.checkboxTargets.some((checkbox) => checkbox.checked);

    if (!anyChecked) {
      // Prevent form submission and show an error
      event.preventDefault();
      document.getElementById('checkbox_warning').classList.remove('d-none');
    }
  }


  connect() {
    console.log("Common Controller connected");
    this.onDOMContentLoaded();
  }

  onDOMContentLoaded() {
    console.log("DOM fully loaded and parsed");
  }

  showPassword() {
    let current_password_input = this.currentPasswordInputTarget;
    if (current_password_input.type === "password") {
      current_password_input.type = "text";
    } else {
      current_password_input.type = "password";
    }
  }

  submitTargetForm(event) {
    const targetFormId = this.targetValue; // Get the value from the static value
    const targetForm = document.getElementById(targetFormId);

    if (targetForm) {
      console.log("##### Target Form ID: " + targetFormId);
      targetForm.submit();
    } else {
      console.error("Form not found with ID: " + targetFormId);
    }
  }

  formatPhoneNumber(event) {
    const input = event.target;
    const phoneNumber = input.value.replace(/\D/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength > 10) {
      input.value = phoneNumber.slice(0, 10);
    }

    if (phoneNumberLength > 6) {
      input.value = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    } else if (phoneNumberLength > 3) {
      input.value = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else {
      input.value = phoneNumber;
    }
  }

}
